<script setup lang="ts">
import { LeaseStatus } from '@register'
import leaseStatusOptions, {
  leaseStatusObject,
  type LeaseStatusObject,
} from '~/constants/LeaseStatus'
import formkitTheme from '@ui/formkit/formkit.theme'
import { twMerge } from 'tailwind-merge'

const props = defineProps<{ modelValue: LeaseStatus }>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: LeaseStatus): void
}>()
const modelValue = useVModel(props, 'modelValue', emit)

const { lease } = useCurrentLeaseDocument()

const onChange = (option: LeaseStatusObject & { value: LeaseStatus }) => {
  modelValue.value = option.value
}

const color = computed(
  () =>
    'rounded-lg border px-5 h-9 ' +
    leaseStatusObject[modelValue.value as keyof typeof leaseStatusObject]
      ?.color,
)

const options = computed(() =>
  leaseStatusOptions.filter(
    (item) =>
      // Filter out DELETED status
      item.value !== LeaseStatus.DELETED &&
      // Filter out DRAFT status if lease is published
      (item.value !== LeaseStatus.DRAFT || lease.value.isPublished === false),
  ),
)
</script>

<template>
  <div>
    <FormListbox
      :options="options"
      format="option"
      class="relative"
      button-inner-class="text-sm font-semibold flex"
      button-icon-class="hidden"
      :input-outer-class="color"
      :class="color"
      :dropdown-wrapper-class="
        twMerge(formkitTheme.listbox.dropdownWrapper, 'w-64')
      "
      :model-value="modelValue"
      @update:model-value="onChange"
    />
  </div>
</template>

<style lang="postcss" scoped>
:deep(.multiselect) {
  @apply cursor-pointer;
  .multiselect__select {
    @apply h-full w-10 bg-transparent;
  }
  .multiselect__tags {
    @apply w-auto py-2 pl-0 pr-3;
  }
  .multiselect__content-wrapper {
    @apply -ml-5 -mt-1.5 w-64;
  }
}
</style>
