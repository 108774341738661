<script setup lang="ts">
import { useModal } from 'vue-final-modal'
import ModalChangeLeaseName from './ModalChangeLeaseName.vue'

import { useCurrentLeaseDocument } from '@register'

// Lease document
const { documentName } = useCurrentLeaseDocument()

const name = computed<string>(() => documentName.value ?? '')
const { open, close } = useModal({
  component: ModalChangeLeaseName,
  attrs: {
    name: name.value,
    onClose() {
      close()
    },
  },
})
</script>

<template>
  <span class="flex grow">
    <span
      role="button"
      tabindex="0"
      class="lease-name group flex-1 grow font-semibold"
      @click="open"
      @keyup.enter="open"
    >
      <Tooltip
        placement="top"
        :content="documentName"
        class="border-gray-750 border bg-gray-900 text-xs"
        outer-class="relative inline-flex"
      >
        <span
          class="group-hover:text-primary line-clamp-2 px-2 text-base text-white group-hover:underline lg:line-clamp-1"
        >
          {{ documentName }}
        </span>
      </Tooltip>
    </span>
  </span>
</template>
