<script setup lang="ts">
interface Props {
  id?: string
  title?: string
  collapsable?: boolean
  sectionClass?: string
  collapsed?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  collapsable: false,
  id: undefined,
  title: undefined,
  sectionClass: '',
  collapsed: (props) => !!props.collapsable,
})

const emit = defineEmits<{
  (event: 'update:collapsed', value: boolean): void
}>()
const collapsed = useVModel(props, 'collapsed', emit, { passive: true })

const onClick = () => {
  if (!props.collapsable) return
  collapsed.value = !collapsed.value
  if (!collapsed.value) {
    //TODO: open the first section
  }
}

const bus = useEventBus<string>('open:sidebarSection')
bus.on((id: string) => {
  if (id === props.id || id === 'open-all-groups') {
    collapsed.value = false
  } else if (id === 'close-all-groups') {
    collapsed.value = true
  }
})

const bus2 = useEventBus<string>('exclusiveOpen:sidebarSection')
bus2.on((id: string) => {
  if (id === props.id) {
    collapsed.value = false
  } else {
    collapsed.value = true
  }
})
</script>

<template>
  <div class="pl-2 pr-1">
    <div
      :id="id"
      v-auto-animate="{ duration: 100 }"
      :class="{
        'my-4 rounded-lg bg-white/5 pb-px': !collapsed,
      }"
    >
      <div
        role="button"
        tabindex="0"
        class="flex items-center py-2 pl-3 hover:bg-gray-700"
        :class="[
          sectionClass,
          collapsable && 'cursor-pointer',
          !collapsed
            ? 'bg-gray-550/20 sticky -top-4 z-10 rounded-t-md backdrop-blur-3xl backdrop-saturate-[180%]'
            : 'rounded-lg',
        ]"
        @click="onClick"
        @keyup.enter="onClick"
      >
        <slot name="title">
          <h3 v-if="title" class="w-full truncate text-xs font-bold uppercase">
            {{ title }}
          </h3>
        </slot>
        <div v-if="collapsable" class="flex self-center pr-2">
          <NuxtIcon :name="collapsed ? 'chevron-down' : 'chevron-up'" filled />
        </div>
      </div>

      <ul v-if="!collapsed" class="my-2 space-y-2">
        <slot />
      </ul>
    </div>
  </div>
</template>
