<script setup lang="ts">
import {
  leaseSectorOptions,
  type LeaseSectorObject,
} from '~/constants/LeaseSector'
import formkitTheme from '@ui/formkit/formkit.theme'
import { twMerge } from 'tailwind-merge'
import { Sector } from '@register'

const props = defineProps<{ modelValue: Sector }>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: Sector): void
}>()
const modelValue = useVModel(props, 'modelValue', emit)

const onChange = (option: LeaseSectorObject & { value: Sector }) => {
  modelValue.value = option.value
}
</script>

<template>
  <div class="">
    <FormListbox
      :options="leaseSectorOptions"
      format="option"
      class="relative relative h-9 cursor-pointer rounded-lg border border-gray-500 px-5 hover:!bg-opacity-50"
      button-icon-class="hidden"
      input-outer-class="rounded-lg border border-gray-550 bg-gray-550/20 px-5 h-9"
      :dropdown-wrapper-class="
        twMerge(formkitTheme.listbox.dropdownWrapper, 'w-52')
      "
      :model-value="modelValue"
      @update:model-value="onChange"
    >
      <template #selection="{ value, displayValue }">
        <div class="flex items-center">
          <NuxtIcon
            v-if="value?.icon"
            :name="value.icon"
            filled
            class="mr-2 mt-0.5 text-lg"
          />

          <span class="text-sm font-semibold text-gray-200">
            {{ displayValue }}
          </span>
        </div>
      </template>

      <template #listitem="{ option, label }">
        <div class="flex items-center">
          <NuxtIcon :name="option.icon" filled class="mr-2 text-lg" />

          <span :class="formkitTheme.listbox.listitemInner">
            {{ label }}
          </span>
        </div>
      </template>
    </FormListbox>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.multiselect) {
  @apply cursor-pointer;

  .multiselect__select {
    @apply h-full w-10 bg-transparent;
  }

  .multiselect__tags {
    @apply w-auto py-2 pl-0 pr-3;
  }

  .multiselect__content-wrapper {
    @apply -ml-5 -mt-1.5 w-52;
  }
}
</style>
