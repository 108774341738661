import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { ILeaseRegisterLease, IPatchLeaseRequest } from '@register'

export function useApiUpdateLeaseDocument(companyId: string, leaseId: string) {
  const queryClient = useQueryClient()

  let controller: AbortController | null = null
  let signal: AbortSignal | null = null

  return useMutation({
    mutationFn: async (body: IPatchLeaseRequest) => {
      // If a previous request is in progress, abort it
      if (controller) {
        controller.abort()
      }

      // Create a new AbortController and signal for the upcoming request
      controller = new AbortController()
      signal = controller.signal

      return fetchAuthorized<ILeaseRegisterLease>(
        `/companies/${companyId}/leaseRegister/leases/${leaseId}`,
        {
          method: 'PATCH',
          body,
          signal,
        },
      )
    },
    onSuccess: (data, body) => {
      queryClient.setQueryData(['getLeaseDocumentId', companyId, leaseId], data)
      queryClient.invalidateQueries({
        queryKey: ['propertyLeases', 'company', companyId],
      })
      queryClient.invalidateQueries({
        queryKey: ['companies', companyId, 'leases', leaseId],
      })

      // If publishing a sublease, invalidate headlease subleases
      if (body.publish === true && data.isSubLease && data.parent) {
        queryClient.invalidateQueries({
          queryKey: [
            'companies',
            companyId,
            'leases',
            data.parent.id,
            'documents',
            'subleases',
          ],
        })
      }
    },
  })
}
