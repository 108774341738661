import type { MaybeRef } from 'vue'
import { useFuse, type UseFuseOptions } from '@vueuse/integrations/useFuse'
import type { ISidebarItem, ISidebarSection } from '@ui/components/Sidebar'
import { createDefu } from 'defu'
import Fuse, { IFuseOptions } from 'fuse.js'

const DEFAULT_FUSE_OPTIONS: IFuseOptions<ISidebarSection> = {
  keys: ['items.title'],
  threshold: 0.3,
  includeMatches: true,
  findAllMatches: false,
  shouldSort: false,
}

const fuseOptionsMerge = createDefu((obj, key, value) => {
  if (Array.isArray(obj[key]) && typeof value === 'function') {
    obj[key] = value(obj[key])
    return true
  }
  // Replace array instead of concat
  if (Array.isArray(obj[key]) && Array.isArray(value)) {
    obj[key] = value
    return true
  }
})

export const useReviewSidebarSearch = <
  TSection extends ISidebarSection = ISidebarSection,
  TItem extends ISidebarItem = TSection['items'][number],
>(
  search: MaybeRef<string>,
  sections: MaybeRef<TSection[]>,
  options: MaybeRef<UseFuseOptions<TSection>> = {},
) => {
  const { results: _results, fuse } = useFuse<TSection>(
    search,
    sections,
    computed(() =>
      fuseOptionsMerge(toValue(options), {
        fuseOptions: DEFAULT_FUSE_OPTIONS,
        matchAllWhenSearchEmpty: true,
      }),
    ),
  )

  const itemsFuseOptions = computed(
    () =>
      fuseOptionsMerge(
        {
          keys: (keys: string[]) =>
            keys
              .filter((key) => key.startsWith('items.'))
              .map((key) => key.replace('items.', '')),
        },
        toValue(options)?.fuseOptions,
        DEFAULT_FUSE_OPTIONS,
      ) as IFuseOptions<TItem>,
  )

  const results = computed(() => {
    return _results.value.map(({ item }) => {
      // Initialize Fuse.js for the nested items array
      const itemsFuse = new Fuse<TItem>(item.items, itemsFuseOptions.value)

      // Perform the search on the nested items array
      const _search = toValue(search)
      const filteredItems = _search
        ? itemsFuse.search(_search).map(({ item }) => item)
        : item.items

      // Return a new object with the filtered items array
      return {
        ...item,
        items: filteredItems,
      } as TSection
    })
  })

  return {
    results,
    fuseResults: _results,
    fuse,
  }
}
