<script lang="ts" setup>
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { LeaseStatus, Sector } from '@register'

defineProps<{
  status: LeaseStatus
  sector: Sector
}>()

const { lease } = useCurrentLeaseDocument()

const emit = defineEmits<{
  (e: 'update:status', value: LeaseStatus): void
  (e: 'update:sector', value: Sector): void
  (e: 'click:notes'): void
}>()
</script>

<template>
  <section class="border-b border-gray-700 pb-4 pr-2">
    <div class="mt-4 flex flex-wrap gap-4">
      <div class="flex flex-auto gap-4">
        <LeaseReviewStatusInput
          :model-value="status"
          @update:model-value="emit('update:status', $event)"
        />

        <LeaseReviewSectorInput
          :model-value="sector"
          @update:model-value="emit('update:sector', $event)"
        />
      </div>

      <Button
        color="opacitySecondary"
        icon="document-edit"
        class="h-9"
        @click.stop="emit('click:notes')"
      >
        Notes
        <span v-if="lease.notesCount" class="text-gray-400">
          ({{ lease.notesCount }})
        </span>
      </Button>

      <Menu
        v-if="$slots.actions"
        ref="popover"
        v-slot="{ open }"
        as="div"
        class="relative z-50"
      >
        <MenuButton
          class="hover:border-primary hover:bg-primary focus-visible:bg-primary mb-1 rounded-md border border-transparent p-2 hover:text-black focus-visible:text-black focus-visible:outline-none"
          :class="{
            'bg-primary text-black': open,
          }"
        >
          <NuxtIcon name="kebab-menu" />
        </MenuButton>

        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-from-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-from-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <MenuItems
            class="bg-gray-650 absolute right-0 z-10 mt-2 flex origin-top-right flex-col divide-y divide-gray-600 rounded-md border border-gray-600 shadow-2xl ring-1 ring-black ring-opacity-5 focus-visible:outline-none"
          >
            <slot name="actions" />
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </section>
</template>
